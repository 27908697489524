import TrelloBoards from './boards';
import TrelloCards from './cards';

// Attach our handlers to the window object if they aren't already there
if (!window.TrelloCards) {
  window.TrelloCards = new TrelloCards();
}
if (!window.TrelloBoards) {
  window.TrelloBoards = new TrelloBoards();
}
