import debounce from 'lodash.debounce';
import forEach from 'lodash.foreach';

import { cardUrlRegex, elInViewport, isAllowedOrigin, isValidId, togglePolling } from './utils';

export default class TrelloCards {
  constructor() {
    this.version = 'BUILD_VERSION';
    this.activeCards = {};

    const receiveMessage = (event) => {
      if (!isAllowedOrigin(event.origin)) {
        return;
      }

      if (event.data.command === 'resize' && event.data.secret) {
        const card = this.activeCards[event.data.secret];
        if (!card || !card.el) {
          return;
        }
        const newHeight = event.data.options.height;
        if (newHeight !== undefined) {
          card.el.height = newHeight;
          if (typeof this.onResize === 'function') {
            this.onResize({
              height: event.data.options.height,
              width: parseInt(card.el.width, 10),
            });
          }
        }
        togglePolling(card.el, event.origin, event.data.secret, elInViewport(card.el));
      }
    };

    window.addEventListener('message', receiveMessage, false);
    window.addEventListener(
      'resize',
      debounce(() => this.reassessVisibility(), 1000)
    );
    window.addEventListener(
      'scroll',
      debounce(() => this.reassessVisibility(), 1000)
    );
    if (document.readyState === 'complete') {
      this.load();
    } else {
      window.addEventListener('load', () => this.load());
    }
  }

  reassessVisibility() {
    forEach(this.activeCards, (card, secret) => {
      const { el, host } = card;
      if (document.body.contains(el)) {
        togglePolling(el, host, secret, elInViewport(el));
      } else {
        // This card is not in the DOM
      }
    });
  }

  createCardIframe(host, id, name, compact, opts = {}) {
    const iframe = document.createElement('iframe');
    const targetOrigin = encodeURIComponent(window.location.origin);

    const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const secret = Array(24)
      .join()
      .split(',')
      .map(() => alphabet.charAt(Math.floor(Math.random() * alphabet.length)))
      .join('');

    iframe.src =
      `${host}/embed/card?` +
      `id=${id}&targetOrigin=${targetOrigin}&secret=${secret}&compact=${compact === true}`;
    iframe.width = 316;
    iframe.height = 152;
    iframe.style.border = 'none';
    iframe.style.overflow = 'hidden';
    iframe.className = 'trello-card trello-card-rendered';
    if (compact) {
      iframe.classList.add('trello-card-compact');
    }
    if (opts && opts.apiToken && opts.apiKey) {
      const { apiKey, apiToken } = opts;
      iframe.name = JSON.stringify({ apiKey, apiToken });
    }
    iframe.onload = opts.onLoad;

    this.activeCards[secret] = { el: iframe, host };

    return iframe;
  }

  create(idOrUrl, el, opts = { compact: false }) {
    const { compact, apiKey, apiToken, onLoad, onResize } = opts;

    let host = 'https://trello.com';

    let id = idOrUrl;
    if (cardUrlRegex.test(id)) {
      const parsed = cardUrlRegex.exec(id);
      if (!parsed || !parsed[1] || !parsed[2]) {
        throw new Error('Invalid Trello card url');
      }
      [, host, id] = parsed;
    }
    if (!isValidId(id)) {
      // this doesn't look like an id to us
      throw new Error('Invalid Trello card id');
    }
    this.onResize = onResize;

    const iframe = this.createCardIframe(host, id, 'Trello Card', compact, {
      apiKey,
      apiToken,
      onLoad,
    });

    el.appendChild(iframe);
  }

  load(el, opts = { compact: false, allAnchors: false }) {
    const { allAnchors, compact, apiToken, apiKey } = opts;
    const selector = allAnchors
      ? 'a'
      : 'blockquote.trello-card>a, blockquote.trello-card-compact>a';
    const cardPlaceholders = (el || document).querySelectorAll(selector);

    forEach(cardPlaceholders, (anchor) => {
      const { href } = anchor;
      const parsed = cardUrlRegex.exec(href);
      if (!parsed || !parsed[1] || !parsed[2]) {
        return;
      }
      const host = parsed[1];
      const id = parsed[2];
      const name = anchor.innerText;
      const isCompact =
        compact === true || anchor.parentElement.classList.contains('trello-card-compact');
      const iframe = this.createCardIframe(host, id, name, isCompact, { apiKey, apiToken });

      if (allAnchors) {
        // replace the anchor
        anchor.parentNode.replaceChild(iframe, anchor);
      } else {
        // replace the blockquote
        const blockquote = anchor.parentNode;
        blockquote.parentNode.replaceChild(iframe, blockquote);
      }
    });
  }
}
