export const elInViewport = (el) => {
  if (!document.body.contains(el)) {
    return false;
  }
  const bounds = el.getBoundingClientRect();

  return (
    bounds.top <= window.innerHeight &&
    bounds.top + bounds.height >= 0 &&
    bounds.left <= window.innerWidth &&
    bounds.left + bounds.width >= 0
  );
};

export const isAllowedOrigin = (origin) => /^https:\/\/trell(?:o\.com|is\.coffee)$/.test(origin);

export const boardUrlRegex = /^(https:\/\/[^/]*?)\/b\/([a-zA-Z0-9]{8,24})/;
export const cardUrlRegex = /^(https:\/\/[^/]*?)\/c\/([a-zA-Z0-9]{8,24})/;

export const isValidId = (id) => /^[a-f0-9]{24}$/.test(id) || /^[a-zA-Z0-9]{8}$/.test(id);

export const togglePolling = (target, targetOrigin, secret, poll) => {
  if (!target || !target.contentWindow) {
    return;
  }
  target.contentWindow.postMessage(
    {
      secret,
      command: 'polling',
      options: { enabled: poll },
    },
    targetOrigin
  );
};
