import forEach from 'lodash.foreach';

import { boardUrlRegex, isValidId } from './utils';

export default class TrelloBoards {
  constructor() {
    this.version = 'BUILD_VERSION';
    if (document.readyState === 'complete') {
      this.load();
    } else {
      window.addEventListener('load', () => this.load());
    }
  }

  // eslint-disable-next-line class-methods-use-this
  createBoardIframe(host, id, name, compact, opts = {}) {
    const iframe = document.createElement('iframe');
    const targetOrigin = encodeURIComponent(window.location.origin);

    const alphabet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const secret = Array(24)
      .join()
      .split(',')
      .map(() => alphabet.charAt(Math.floor(Math.random() * alphabet.length)))
      .join('');

    iframe.src =
      `${host}/embed/board?` +
      `id=${id}&targetOrigin=${targetOrigin}&secret=${secret}&compact=${compact === true}`;
    iframe.width = 248;
    iframe.height = compact ? 185 : 218;
    iframe.style.border = 'none';
    iframe.style.overflow = 'hidden';
    iframe.className = 'trello-board trello-board-rendered';
    if (compact) {
      iframe.classList.add('trello-board-compact');
    }
    if (opts && opts.apiToken && opts.apiKey) {
      const { apiKey, apiToken } = opts;
      iframe.name = JSON.stringify({ apiKey, apiToken });
    }

    return iframe;
  }

  create(idOrUrl, el, opts = {}) {
    const { apiKey, apiToken } = opts;
    let host = 'https://trello.com';

    let id = idOrUrl;
    if (boardUrlRegex.test(id)) {
      const parsed = boardUrlRegex.exec(id);
      if (!parsed || !parsed[1] || !parsed[2]) {
        throw new Error('Invalid Trello board url');
      }
      [, host, id] = parsed;
    }
    if (!isValidId(id)) {
      // this doesn't look like an id to us
      throw new Error('Invalid Trello board id');
    }

    const iframe = this.createBoardIframe(host, id, 'Trello Board', true, { apiKey, apiToken });

    el.appendChild(iframe);
  }

  load(el, opts = { allAnchors: false }) {
    const { allAnchors, apiKey, apiToken } = opts;
    const selector = allAnchors ? 'a' : 'blockquote.trello-board-compact>a';
    const boardPlaceholders = (el || document).querySelectorAll(selector);

    forEach(boardPlaceholders, (anchor) => {
      const { href } = anchor;
      const parsed = boardUrlRegex.exec(href);
      if (!parsed || !parsed[1] || !parsed[2]) {
        return;
      }
      const host = parsed[1];
      const id = parsed[2];
      const name = anchor.innerText;
      const isCompact = true;
      const iframe = this.createBoardIframe(host, id, name, isCompact, { apiKey, apiToken });

      if (allAnchors) {
        // replace the anchor
        anchor.parentNode.replaceChild(iframe, anchor);
      } else {
        // replace the blockquote
        const blockquote = anchor.parentNode;
        blockquote.parentNode.replaceChild(iframe, blockquote);
      }
    });
  }
}
